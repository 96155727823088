.boss {
    width: 100%;
    display: flex;
    height: 100vh;
    position: relative;
    z-index: 11;
}

.boss .first {
    background: linear-gradient(228deg, #0279a4 -22.97%, #473198 120.53%);
    padding: 50px;
    width: 50%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 5px black;
    /* position: relative;
    z-index: 1; */
}
.boss .foto{
    position: absolute;
    top: 55%;
    transform: translate(-50% , -50%);
    left: 50%;
}

.boss .first .box-top-left {
    display: flex;
    align-items: center;
    padding-top: 50px;
}
.boss .first .fintech{
    width: 200px;
    height: 20px;
}
.boss .first .box-top-left i.fa-solid {
    color: white;
    font-size: 50px;
}

.boss .first .box-top-left .text-top-left {
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
    color: white;
    font-family: Montserrat, sans-serif;
    padding-left: 17px;
}

.boss .first .center-left {
    padding: 70px 0px;
}

.boss .first .center-left .center-text {
    font-size: 25px;
    font-family: Montserrat, sans-serif;
    padding-bottom: 25px;
    font-weight: 700;
    color: white;
}

.boss .first .center-left .list {
    display: grid;
    align-items: center;
    grid-gap: 10px;
    position: relative;
    z-index: 1;
}

.boss .first .center-left .list .left-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    font-weight: 400;
    font-size: 18px;
    color: white;
    font-family: Montserrat, sans-serif;
}
.boss .first .center-left .list .left-text img{
    width: 25px;
    height: 25px;
}
.boss .first .left-bottom {
    display: flex;
    align-items: center;
}
.boss .first .left-bottom img{
    width: 35px;
    height: 40px;
}
.boss .first .left-bottom .bottom-p {
    padding: 12px;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    color: white;
    line-height: 15px;
}

.boss .box {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.boss .box p.location{
    position: absolute;
    display: flex;
    grid-gap: 3px;
    top: 25px;
    right: 30px;
    color: crimson;
    font-size: 25px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
}
.boss .box p.location i.fa{
    padding-right: 5px;
}

.boss .box .second {
    /* padding: 20px; */
    width: 76%;
    position: relative;
    z-index: 1;
}

.boss .box .second h1.right-top {
    font-size: 30px;
    line-height: 30px;
    color: black;
    font-weight: 900;
    font-family: Montserrat, sans-serif;
}
.boss .box .second h1.right-top span{
    color: rgb(48, 168, 255);
}

.boss .box .second p.right {
    font-size: 16px;
    color: #58595b;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 20px;
    font-family: Montserrat, sans-serif;
}

.boss .box .second form {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 20px;
}

.boss .box .second form input:focus {
    outline: none;
}
.boss .box .second form input::placeholder{
    color: #1c212d;
    text-transform: capitalize;
}
.boss .box .second form input {
    padding: 15px;
    font-size: 14px;
    color: #1c212d;
    border: none;
    box-shadow: 0px 0px 2px #7a7a7a;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    background: #fff;
    text-transform: capitalize;
}
.boss .box .second form p.form{
    padding-left: 5px;
    padding-bottom: 8px;
    padding-top: 10px;
}
.react-tel-input .form-control{
    padding: 10px !important;
    height: unset !important;
    width: 100% !important;
}
.react-tel-input .flag-dropdown{
    display: none;
}
.boss .box .second button{
    font-size: 16px;
    background: #f8ad0d;
    border: none;
    border-radius: 15px;
    padding: 15px 0px;
    width: 100%;
    font-weight: 600;
    color: #1c212d;
    cursor: pointer;
    /* margin-top: 20px; */
}
.boss .box .teleg{
    background: linear-gradient(228deg, #0279a4 -22.97%, #473198 120.53%);
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    padding: 15px 0px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    justify-content: center;
    /* margin-top: 20px; */
}
.boss .box .second .right-bottom{
    display: flex;
    padding-top: 20px;
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
}
.boss .box .second .right-bottom p.one{
    padding: 0px 6px;
    border: 2px solid #58595b;
    border-radius: 50%;
    background: white;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #58595b;
}
.boss .box .second .right-bottom p.two{
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #58595b;
}

/* media */
/* xxl 1200px*/
/* xl 992px - 1199px */
@media(max-width:1199px){
    .boss .first .foto img{
        width:260px;
        object-fit: contain;
    }
    .boss .box .second h1.right-top {
        font-size: 29px;
    }
}
/* lg 768px - 991px */
@media(max-width:991px){
    .boss{
        flex-direction: column;
    }
    .boss .first{
        width: 100%;
        border-radius: unset;
        padding: 30px 30px 10px 30px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .boss  .foto{
        display: none;
    }
    .boss .first .box-top-left .text-top-left{
        font-size: 25px;
    }
    .boss .first .box-top-left,
    .boss .first .left-bottom{
        justify-content: center;
    }
    .boss .first .center-left{
        padding: 20px 0px;
        display: grid;
        justify-content: center;
    }
    .boss .first .center-left .center-text{
        padding-bottom: 10px;
    }
    .boss .box .second h1.right-top {
        font-size: 25px;
        text-align: center;
        padding-top: 20px;
    }
    .boss .box .second p.right {
        text-align: center;
        padding-bottom: 40px;
    }
    .boss .box{
        width: 100%;
        position:unset;
    }
    .boss .box .second{
        width: 90%;
    }
    .boss .box .second .right-bottom{
        padding: 10px 0px;
    }
    .boss .first .box-top-left{
        padding-top: 20px;
    }
}
/* md 575px - 767px */
@media(max-width:767px){
    .boss .first{
        align-items: start;
    }
    .boss .first .center-left{
        padding: 15px 0px;
    }
    .boss .first .center-left .center-text{
        font-size: 20px;
    }
    .boss .first .center-left .list .left-text{
        font-size: 14px;
    }
}
/* sm 320px - 575px */
@media(max-width:575px){
    .boss .first .fintech{
    width: 150px;
    object-fit: contain;
    }
    .boss .first{
        padding: 20px 20px 10px 20px;
    }
    .boss .first .box-top-left i.fa-solid {
        font-size: 30px;
        padding-right: 5px;
    }
    
    .boss .first .box-top-left{
        padding-top: 20px;
        width: 100%;
    }
    .boss .first .box-top-left .text-top-left{
        font-size: 23px;
        padding-left: 0px;
    }
    .boss .first .center-left .center-text{
        font-size: 20px;
    }
    .boss .first .center-left .list .left-text{
        font-size: 14px;
    }
    .boss .first .center-left .list .left-text img{
        width: 14px;
        height: 13px;
    }
    .boss .box .second .right-bottom p.two,
    .boss .first .left-bottom .bottom-p{
        font-size: 11px;    
    }
    .boss .box .second p.right{
        padding: 10px 0px;
    }
    .boss .box .second h1.right-top{
        font-size: 22px;
    }
    .boss .box p.location{
        top: 20px;
        right: 25px;
        font-size: 20px;
    }
}